import { Grid,Stack, TextField ,Button,useMediaQuery, IconButton, Divider} from '@mui/material'
import NavBar from '../organisms/NavBar'
import React, { useRef, useState } from 'react'
import './contact.css'
import Footer from '../organisms/Footer'
import ContactFormBtn from '../atoms/ContactFormBtn'
import { styled } from '@mui/material/styles';
import { Email, Phone } from '@mui/icons-material'

export default function Contact() {
    //media query 
    const smallScreen = useMediaQuery('(min-width:600px)');

    //refs for email info
    const nameRef = useRef()
    const emailRef = useRef()
    const companyRef = useRef()
    const msgRef = useRef()
    const CustomSubmitButton = styled(Button)(({ theme }) => ({
      background: "rgba(205, 222, 101, 1)",
      color: "green",
      fontFamily: "quicksand",
      letterSpacing: ".156em",
      fontWeight: "bold",
      fontSize: "1em",
      "&:hover": {
        border: "rgba(205, 222, 101, 1) solid .2em",
        background: "transparent",
      },
    }))

    //function to handle form 
    const handleForm = (e)=>{
        console.log(nameRef.current.value)
        console.log(emailRef.current.value)
        console.log(msgRef.current.value)
        console.log(companyRef.current.value)

        
    }



    return (
      <div>
        <Grid container direction={"column"}>
          <div className='contactContainer'>
            <Grid item>
              <Stack
                direction='row'
                justifyContent='space-between'
                
                alignItems='center'
                spacing={12}
                sx={{ paddingTop: "2em" }}
              >
                <div>
                  <h1
                    style={{
                      fontFamily: "Julius Sans One",
                      paddingLeft: smallScreen ? "3em" : "0.5em",
                    }}
                  >
                    SHOBARA FOODS INC.
                  </h1>
                </div>
                <div style={{ float: "right" }}>
                  <NavBar></NavBar>
                </div>
              </Stack>
            </Grid>
            <Grid item>
              <Stack alignItems={"center"}>
                <div className='contactBody'>
                  <h1
                    style={{
                      fontFamily: "quicksand",
                      textAlign: "center",
                      fontSize: "3em",
                    }}
                  >
                    GET IN TOUCH WITH US
                  </h1>
                  <div>
                    <Stack
                      direction={"row"}
                      spacing={3}
                      justifyContent='center'
                      alignContent={"center"}
                      sx={{
                        fontFamily: "quicksand",
                      }}
                      divider={
                  <Divider orientation='vertical' flexItem sx={{border:'1px solid black'}}/>
                }
                    >
                      <span
                        style={{
                          fontSize: "1.4em",
                          textAlign: "center",
                        }}
                      >
                        <IconButton>
                          <Phone sx={{ mr: ".3em" }} />
                          +254752782015{" "}
                        </IconButton>
                      </span>
                      <span
                        style={{
                          fontSize: "1.4em",
                          textAlign: "center",
                        }}
                      >
                        <IconButton>
                          <Email sx={{ mr: ".3em" }}></Email>
                          info@shobarafoods.biz
                        </IconButton>
                      </span>
                      <span
                        style={{
                          fontSize: "1.4em",
                          textAlign: "center",
                        }}
                      >
                        <IconButton>
                          <Email sx={{ mr: ".3em" }}></Email>
                          admin@shobarafoods.biz
                        </IconButton>
                      </span>
                    </Stack>
                  </div>
                  <form>
                    <Stack
                      spacing={3}
                      direction={"column"}
                      justifyContent='center'
                      alignItems='center'
                      mt='3vw'
                    >
                      <div style={{ width: "100%" }}>
                        <label
                          style={{
                            fontFamily: "quicksand",
                            fontSize: "1.5em",
                          }}
                        >
                          Name
                        </label>
                        <br />
                        <TextField
                          sx={{
                            backgroundColor: "white",
                            width: "90%",
                          }}
                          inputRef={nameRef}
                        ></TextField>
                      </div>
                      <div style={{ width: "100%" }}>
                        <label
                          style={{
                            fontFamily: "quicksand",
                            fontSize: "1.5em",
                          }}
                        >
                          Email
                        </label>
                        <br />
                        <TextField
                          sx={{
                            backgroundColor: "white",
                            width: "90%",
                          }}
                          inputRef={emailRef}
                        ></TextField>
                      </div>
                      <div style={{ width: "100%" }}>
                        <label
                          style={{
                            fontFamily: "quicksand",
                            fontSize: "1.5em",
                          }}
                        >
                          Company
                        </label>
                        <br />
                        <TextField
                          sx={{
                            backgroundColor: "white",
                            width: "90%",
                          }}
                          inputRef={companyRef}
                        ></TextField>
                      </div>
                      <div style={{ width: "100%" }}>
                        <label
                          style={{
                            fontFamily: "quicksand",
                            fontSize: "1.5em",
                          }}
                        >
                          Message
                        </label>
                        <br />
                        <TextField
                          multiline
                          rows={8}
                          sx={{
                            backgroundColor: "white",
                            width: "90%",
                          }}
                          inputRef={msgRef}
                        ></TextField>
                      </div>
                    </Stack>
                    <div
                      style={{
                        float: "right",
                        marginRight: "3em",
                        padding: "1.5em",
                      }}
                    >
                      <CustomSubmitButton
                        variant='contained'
                        onClick={handleForm}
                      >
                        Submit
                      </CustomSubmitButton>
                    </div>
                  </form>
                </div>
              </Stack>
            </Grid>
          </div>
        </Grid>
        <div
          style={{ marginTop: smallScreen ? "150vh" : "105vh" }}
        >
          <Footer />
        </div>
      </div>
    )
}
