/** @format */

import {Stack, Box, Collapse} from "@mui/material"
import {Container} from "@mui/system"
import React, {useEffect, useState} from "react"
import NavbarBtn from "../atoms/NavbarBtn"
import MenuIcon from "@mui/icons-material/Menu"
import Drawer from "./Drawer"
export default function NavBar() {
	const [aniState, setAniSet] = useState(false)
	useEffect(() => {
		setAniSet(true)
	}, [])
	return (
		<Container
			sx={{
				background: "rgba(221, 242, 166, 1)",
				borderRadius: "60px 0px 0px 60px",
				border: "rgba(205, 222, 101, 1) solid .2em",
			}}
		>
			<Collapse
				orientation="horizontal"
				in={aniState}
				{...(aniState ? {timeout: 1000} : {})}
				mountOnEnter
			>
				<Stack
					direction="row"
					spacing={{md: 2, lg: 3}}
					sx={{
						height: "4em",
						display: {xs: "none", md: "flex", lg: "flex"},
					}}
					justifyContent="right"
					alignItems={"center"}
				>
					<NavbarBtn
						btnLabel="Home"
						btnPath="/"
					/>
					<NavbarBtn
						btnLabel="About"
						btnPath="/about"
					/>
					<NavbarBtn
						btnLabel="Contact"
						btnPath="/contact"
					/>
					<NavbarBtn
						btnLabel="Our Products"
						btnPath="/mkt
              "
					/>
					<NavbarBtn
						btnLabel="Our Services"
						btnPath="/service"
					/>
				</Stack>
				<Box
					sx={{
						height: "4em",
						padding: "0.5em",
						display: {xs: "flex", md: "none"},
					}}
				>
					<Drawer></Drawer>
				</Box>
			</Collapse>
		</Container>
	)
}
