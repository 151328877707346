import { Divider, Grid, Stack } from '@mui/material'
import { textAlign } from '@mui/system'
import React from 'react'
import CustomFooterInput from '../atoms/CustomFooterInput'
import FooterNavBarBtn from '../atoms/FooterNavBarBtn'

export default function Footer() {
  return (
    <div
      style={{
        backgroundColor: "rgba(221, 242, 166, 1)",
        borderRadius: "66px 66px 0px 0px",
        marginTop: "3em",
      }}
    >
      <Grid
        container
        justifyContent={"center"}
        paddingBottom='2em'
        spacing={{ xs: 3, md: 6 }}
        alignItems='center'
        direction={{ xs: "column", md: "row" }}
      >
        <Grid item width={{ xs: "90%", md: "33%" }}>
          <Stack
            direction={"column"}
            spacing={1}
            sx={{
              fontFamily: "quicksand",
              padding: "1em",
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <span style={{ fontSize: "1.8em" }}>Newsletter</span>
            <span style={{ fontSize: "1.1em" }}>
              Please enter your email to subcribe{" "}
            </span>
            <span>
              <CustomFooterInput />
            </span>
          </Stack>
        </Grid>

        <Grid item width={{ xs: "90%", md: "10%" }}>
          <Stack
            direction={"column"}
            spacing={2}
            alignItems='center'
            justifyContent='center'
            sx={{ fontFamily: "quicksand" }}
          >
            <FooterNavBarBtn
              btnLabel='Marketpalce'
              btnPath='/s'
            />
            <FooterNavBarBtn btnLabel='About' btnPath='/s' />
            <FooterNavBarBtn btnLabel='Contact' btnPath='/s' />
          </Stack>
        </Grid>
        <Grid item width={{ xs: "90%", md: "33%" }}>
          <Stack
            direction={"column"}
            spacing={1}
            sx={{ fontFamily: "quicksand", padding: "1em" }}
          >
            <span
              style={{ fontSize: "1.4em", textAlign: "center" }}
            >
              +254752782015
            </span>
            <span
              style={{ fontSize: "1.4em", textAlign: "center" }}
            >
              info@shobarafoods.biz
            </span>
            <span
              style={{ fontSize: "1.4em", textAlign: "center" }}
            >
              admin@shobarafoods.biz
            </span>
          </Stack>
        </Grid>
      </Grid>
    </div>
  )
}
