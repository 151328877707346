import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import React from 'react'
import FooterNavBarBtnTwo from './FooterNavBarBtnTwo';

export default function CustomFooterInput() {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      <div>
        <TextField
            size='small'
            sx={{background:'white'}}
            InputProps={{
                endAdornment:<FooterNavBarBtnTwo btnLabel="Home" btnPath='/s'/>
            }}/>
      </div>
    </Box>
  )
}
