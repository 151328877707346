import { Button } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles';

export default function FooterNavBarBtn(props) {
  const CustomFooterButton = styled(Button)(({ theme }) => ({
    background: "transparent",
    color: "green",
    fontFamily: "quicksand",
    letterSpacing: ".156em",
    fontSize: "0.8em",
    "&:hover": {
      border: "rgba(205, 222, 101, 1) solid .2em",
      background: "transparent",
    },
  }))
  return (
    <div>
        <Link to={props.btnPath} style={{textDecoration:"none"}}>
            <CustomFooterButton variant="contained">
              {props.btnLabel}
            </CustomFooterButton>
        </Link>
    </div>
  )
}