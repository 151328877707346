import { Grid,Stack,useMediaQuery } from '@mui/material'
import NavBar from '../organisms/NavBar'
import React from 'react'
import '../pages/about.css'
import Footer from '../organisms/Footer'
import aboutImg from '../img/aboutImg.svg'
import VisibilityIcon from '@mui/icons-material/Visibility';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
export default function About() {
    const smallScreen = useMediaQuery('(min-width:600px)');
  return (
    <div>
      <Grid container direction={"column"}>
        <div className='aboutContainer'>
          <Grid item>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              spacing={12}
              sx={{ paddingTop: "2em" }}
            >
              <div>
                <h1
                  style={{
                    fontFamily: "Julius Sans One",
                    paddingLeft: smallScreen ? "3em" : "0.5em",
                  }}
                >
                  SHOBARA FOODS INC.
                </h1>
              </div>
              <div style={{ float: "right" }}>
                <NavBar></NavBar>
              </div>
            </Stack>
          </Grid>
          <Grid item>
            <Stack
              direction={{ xs: "column", md: "row" }}
              alignItems='center'
              marginBottom={"5em"}
              justifyContent={"space-evenly"}
            >
              <div style={{ textAlign: "center", width: "40%" }}>
                <h1
                  style={{
                    fontFamily: "quicksand",
                    fontSize: "3em",
                  }}
                >
                  About Us
                </h1>
                <span
                  style={{
                    fontFamily: "Inconsolata",
                    fontSize: "1.5em",
                  }}
                >
                  <p>
                    Shobara Foods Inc. is a commercial producer,
                    packer, and exporter of quality organic and
                    conventional fresh fruits in Kenya.
                  </p>
                  <p>
                    Shobara produces, processes, packages, and
                    supplies premium quality fruits for the
                    international export market on a large scale
                    and with a high level of service.
                  </p>
                  <p>
                    Avocado and mango fruit are among the fresh
                    fruits in our export inventory. The new
                    products under development include apples,
                    passion fruit, and pineapples, which will be
                    available at the end of 2022.
                  </p>
                </span>
              </div>
              <div>
                <img className='imgAbout' src={aboutImg}></img>
              </div>
            </Stack>
          </Grid>
          <Grid item>
            <Stack
              direction={{ xs: "column", md: "row" }}
              alignItems='center'
              marginBottom={"5em"}
              justifyContent={"space-evenly"}
            >
              <div style={{ textAlign: "center", width: "25%" }}>
                <h1
                  style={{
                    fontSize: "2.5em",
                    fontFamily: "quicksand",
                  }}
                >
                  Our Mission
                </h1>
                <TaskAltIcon
                  sx={{ fontSize: "6em" }}
                ></TaskAltIcon>
                <p
                  style={{
                    fontSize: "1.5em",
                    fontFamily: "Inconsolata",
                  }}
                >
                  Deliver consistent, high-quality, and certified
                  organic products to the international market
                  through own production and farmer empowerment.
                </p>
              </div>
              <div style={{ textAlign: "center", width: "25%" }}>
                <h1
                  style={{
                    fontSize: "2.5em",
                    fontFamily: "quicksand",
                  }}
                >
                  Our Vision
                </h1>
                <VisibilityIcon
                  sx={{ fontSize: "6em" }}
                ></VisibilityIcon>
                <p
                  style={{
                    fontSize: "1.5em",
                    fontFamily: "Inconsolata",
                  }}
                >
                  To be the preferred producer and supplier of
                  premium organic Kenyan fresh produce for the
                  international market.
                </p>
              </div>
            </Stack>
          </Grid>
          <Grid item>
            <Stack
              direction={{ xs: "column", md: "row" }}
              alignItems='left'
              marginBottom={"5em"}
              justifyContent={"space-evenly"}
            >
              <div style={{ textAlign: "left", width: "30%" }}>
                <h1
                  style={{
                    fontSize: "2.5em",
                    fontFamily: "quicksand",
                  }}
                >
                  Why Shobara as an Importer?
                </h1>
                <p
                  style={{
                    fontSize: "1.5em",
                    fontFamily: "Inconsolata",
                  }}
                >
                  <ul>
                    <li>
                      Highly dependable in terms of quality and
                      quantity sustainability.
                    </li>
                    <li>
                      Provide adaptable and customizable
                      solutions.
                    </li>
                    <li>Professional and competent crew.</li>
                    <li>
                      For market dependability, operational
                      excellence is required.
                    </li>
                    <li>
                      Option 1 and Option 2 Global GAP
                      certification
                    </li>
                    <li>Shipping by air and sea</li>
                    <li>
                      A weekly export capacity of up to 15,
                      40-foot reefer containers via sea and
                      comparable by air.
                    </li>
                    <li>
                      KEPHIS certified horticultural exporter.
                    </li>
                    <li>HCDA export authorization.</li>
                    <li>
                      Global social practice risk assessment
                      (GRASP)
                    </li>
                  </ul>
                </p>
              </div>
              <div style={{ textAlign: "left", width: "30%" }}>
                <h1
                  style={{
                    fontSize: "2.5em",
                    fontFamily: "quicksand",
                  }}
                >
                  Why Shobara as a Farmer and Producer?
                </h1>
                <p
                  style={{
                    fontSize: "1.5em",
                    fontFamily: "Inconsolata",
                  }}
                >
                  <ul>
                    <li>
                      Through a contractual working agreement,
                      provide a solid and sustained market.
                    </li>
                    <li>Competitive prices</li>
                    <li>
                      Provide technical assistance to improve
                      quality and quantity
                    </li>
                    <li>
                      Facilitate access to a variety of
                      additional government facilities and
                      services, as well as connections with
                      development partners
                    </li>
                    <li>
                      Create a communication and consulting
                      platform
                    </li>
                    <li>
                      Transparent and technologically driven
                      value point
                    </li>
                  </ul>
                </p>
              </div>
            </Stack>
          </Grid>
        </div>
      </Grid>
      <Footer />
    </div>
  )
}
