/** @format */

import React from "react"
import NavBar from "../organisms/NavBar"
import bgImg from "../img/landingBackgroudImg.png"
import "../pages/ld.css"
import {Grid, Stack, Typography, useMediaQuery} from "@mui/material"
import Footer from "../organisms/Footer"
import CustomCardGroup from "../organisms/CustomCardGroup"
import "./landing.css"
import Slider from "../organisms/Slider"

export default function LandingPage() {
	const smallScreen = useMediaQuery("(min-width:600px)")
	return (
		<div>
			<Grid
				container
				direction={"column"}
			>
				<div className="ldContainer">
					<Grid item>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							spacing={12}
							sx={{paddingTop: "2em"}}
						>
							<div>
								<h1
									style={{
										fontFamily: "Julius Sans One",
										marginLeft: smallScreen ? "3em" : "0.5em",
									}}
								>
									SHOBARA FOODS INC.
								</h1>
							</div>
							<div style={{justifyContent: "end"}}>
								<NavBar></NavBar>
							</div>
						</Stack>
					</Grid>
					<Grid item>
						<Stack
							direction={"row"}
							justifyContent={"center"}
							spacing={12}
						>
							<Typography
								sx={{
									textAlign: "center",
									lineHeight: "1.5em",
									fontSize: {xs: "10vw", md: "5vw", lg: "4vw"},
									fontFamily: "QUICKSAND",
									fontWeight: "500",
									mt: "5vh",
								}}
							>
								Your Choice,
								<br />
								<span
									style={{fontSize: {md: "3vw", lg: "3vw"}, fontWeight: "500"}}
								>
									{" "}
									For Fresh and quality Produce and <br /> Competitive prices{" "}
								</span>
							</Typography>
						</Stack>
					</Grid>
				</div>
				<Grid item>
					<h1
						style={{
							fontFamily: "Julius Sans One",
							padding: ".2em",
							fontSize: "2.4em",
							textAlign: "center",
							fontWeight: "normal",
						}}
					>
						Why Choose Us
					</h1>
					<CustomCardGroup></CustomCardGroup>
				</Grid>
				<Grid
					item
					marginBottom={"5em"}
				>
					{/* <h1
						style={{
							fontFamily: "Julius Sans One",
							padding: ".2em",
							fontSize: "2.4em",
							textAlign: "center",
							fontWeight: "normal",
						}}
					>
					</h1>
					<Stack>
						<Slider />
					</Stack> */}
				</Grid>
			</Grid>
			<Footer />
		</div>
	)
}
