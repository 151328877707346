import  React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

export default function CustomCardGroup() {
  const spanSty ={
    fontFamily:'Inconsolata',
    fontSize:'0.7em',
    fontWeight:'bold',
    marginTop:"auto",
    marginBottom:'auto'
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent:'center',
        '& > :not(style)': {
          m: 1,
          width: 325,
          textAlign:'center',
          fontFamily:'Julius Sans One',
          fontSize:'1.8em',
          fontWeight:'400',
          padding:'1em',

        },
      }}
    >
      <Paper elevation={1}>
        QUALITY
        <hr style={{color:'green'}}/>
        <span style={spanSty}>
        Highly dependable in terms of quality and quantity sustainability.
        </span>
      </Paper>
      <Paper elevation={1}>
      convenience
        <hr style={{color:'green'}}/>
        <span style={spanSty}>
        Facilitate access to a variety of additional government facilities and services, as well as
connections with development partners
        </span>
      </Paper>
      <Paper elevation={1}>
        Certified
        <hr style={{color:'green'}}/>
        <span style={spanSty}>
          <ul>
            <li>KEPHIS certified horticultural exporter.</li>
            <li>HCDA export authorization.</li>
            <li>Global social practice risk assessment (GRASP)</li>
          </ul>
        </span>
      </Paper>
    </Box>
  );
}