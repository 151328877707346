/** @format */

import {Button, Grid, Paper, Stack, useMediaQuery} from "@mui/material"
import NavBar from "../organisms/NavBar"
import React from "react"
import "../pages/about.css"
import Footer from "../organisms/Footer"
import aboutImg from "../img/aboutImg.svg"
import MarketPLaceCard from "../atoms/MarketPLaceCard"
import placeHolderImg from "../img/placeHolder.png"
import mangoes from "../img/mangoes.jpg"
import avocado from "../img/avocado.jpg"
import Basil from "../img/Basil.jpg"
import Chives from "../img/Chives.jpg"
import LemonThyme from "../img/LemonThyme.jpg"
import Lemongrass from "../img/LemonThyme.jpg"
import Marjoram from "../img/Marjoram.jpg"
import Melissa from "../img/Melissa.jpg"
import Rosemary from "../img/Rosemary.jpg"
import Oregano from "../img/Oregano.jpg"
import Tarragon from "../img/Tarragon.jpg"
import WinterThyme from "../img/WinterThyme.jpg"
import {Block, WrapText} from "@mui/icons-material"
import NavbarBtn from "../atoms/NavbarBtn"
var items = [
	{
		name: "Mangoes",
		p1: "Kenyan mangos are some of the world's best, making Kenya a vital supplier of luscious and nutritious mangos for the world's main mango markets.",
		p2: "Did you realize that mangos are the world&#39;s most popular fruit? This mature golden, succulent fruit with a nice flavor and pleasant scent has been a staple of many people&#39;s daily diets worldwide for many years.",
		p3: "Shobara Foods Inc. offers only the best Kenyan mangoes appropriate for the worldwide export market. Kenya mangos have a sweet flavor and are commonly used as a dish. It is also tasty when the pulp of raw mangoes is cooked, or the juice from mature fruits is extracted.",
		p4: "Kenya mangos have several health advantages other than being used as food. Mango fruit, for example, includes fibres, which are essential for digestion and, in particular, reducing constipation. Kenya mangos are also high in calories, lipids, vitamins, and potassium, all of which aid in numerous metabolic activities in the human body.",
		varHeading: "Apple variety, Ngowe variety.",
		ava: "Available from August through March.",
		size: "7-13",
		img: mangoes,
	},
	{
		name: "Avocadoes",
		p1: "Shobara Foods Inc. is a proud exporter of East African avocados. Kenya has a reputation for producing the best avocados globally, equally organic and conventional.",
		p2: "Consumers gain a new taste of excellent, nutritious avocados, while producers are guaranteed healthy returns. The Shobara Foods Inc. narrative continues to build momentum, continually surpassing new milestones and remaining firm in its long-held confidence that the best is yet to come.",
		heading1: "Health benefits for avocadoes",
		listItem1:
			"Avocados are beneficial to heart health. - This delectable fruit serves a high concentration of folic acid (23%). Folic acid is a vitamin in green leafy vegetables that contributes to general health restoration.",
		listItem2:
			"Improves nutrition uptake - Consuming avocados ensures that your body absorbs certain nutrients. When you consume avocados, you absorb more carotenoids from other fruits and vegetables. According to certain studies, a person who consumes avocados absorbs carotenoids five times more effectively than someone who does not consume avocados.",
		listItem3:
			"Hair is repaired. - It is the most well-known avocado advantage. Because of the high concentration of vitamins and fat in it, it heals dull, dry hair.",
		listItem4:
			"Reduces cholesterol - Avocados contain a lot of beta-sitosterol, which has been demonstrated to lower cholesterol levels. Avocados include monounsaturated fat (good fat), which lowers LDL cholesterol (bad cholesterol).",
		listItem5:
			"Vitamin E is present in the avocado fruit. - Avocados contain a lot of vitamin E. When you take this vitamin daily, your body is completely prepared to battle sickness. Furthermore, Vitamin E revitalizes the skin because of its antioxidant characteristics, making it smooth and supple.",
		varHeading: "Hass avocado, Greenskin varieties",
		ava: "Availability: Throughout the year.",
		size: "12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32",
		img: avocado,
	},
]

export default function MarketPlace(props) {
	const smallScreen = useMediaQuery("(min-width:600px)")
	const porpList = [
		{
			title: "prop1",
		},
		{
			title: "prop2",
		},
		{
			title: "prop3",
		},
		{
			title: "prop4",
		},
		{
			title: "prop5",
		},
		{
			title: "prop6",
		},
		{
			title: "prop7",
		},
	]
	return (
		<div>
			<Grid
				container
				direction={"column"}
			>
				<div className="aboutContainer">
					<Grid item>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							spacing={12}
							sx={{
								paddingTop: "2em",
							}}
						>
							<div>
								<h1
									style={{
										fontFamily: "Indie Flower",
										paddingLeft: smallScreen ? "3em" : "0.5em",
									}}
								>
									SHOBARA FOODS INC.
								</h1>
							</div>
							<div
								style={{
									float: "right",
								}}
							>
								<NavBar> </NavBar>
							</div>
						</Stack>
					</Grid>
					<Grid
						item
						justifyContent={"center"}
						sx={{
							textAlign: "center",
						}}
					>
						<h1
							style={{
								fontFamily: "Quicksand",
								fontSize: "3em",
							}}
						>
							Fruits
						</h1>
						<Grid
							container
							sx={{
								padding: "2em",
							}}
							justifyContent={"center"}
							alignItems="center"
							spacing={6}
						>
							<Stack
								justifyContent={"center"}
								alignItems="center"
								spacing={6}
							>
								<Paper
									sx={{
										padding: "1em",
										width: "70%",
										backgroundColor: "#ddf2a6",
									}}
								>
									<Stack
										direction={"row"}
										alignItems={"center "}
										sx={{
											textAlign: "Left",
										}}
									>
										<div>
											<img
												src={avocado}
												width="300em"
											></img>
										</div>
										<div>
											<Stack
												direction={"column"}
												sx={{
													padding: "1em",
													textAlign: "Left",
												}}
												alignItems="end"
											>
												<span
													style={{
														fontSize: "2em",
														alignSelf: "flex-start",
													}}
												>
													Avocadoes
												</span>
												<p>
													Shobara Foods Inc.is a proud exporter of East African
													avocados.Kenya has a reputation for producing the best
													avocados globally, equally organic and
													conventional.Consumers gain a new taste of excellent,
													nutritious avocados, while producers are guaranteed
													healthy returns.The Shobara Foods Inc.narrative
													continues to build momentum, continually surpassing
													new milestones and remaining firm in its long - held
													confidence that the best is yet to come.{" "}
												</p>
												<NavbarBtn
													btnPath="readMore"
													btnLabel="Read More"
												>
													Read More
												</NavbarBtn>
											</Stack>
										</div>
									</Stack>
								</Paper>
								<Paper
									sx={{
										padding: "1em",
										width: "70%",
										backgroundColor: "#ddf2a6",
									}}
								>
									<Stack
										direction={"row"}
										alignItems={"center "}
										sx={{
											textAlign: "Left",
										}}
									>
										<div>
											<img
												src={mangoes}
												width="300em"
											></img>
										</div>
										<div>
											<Stack
												direction={"column"}
												sx={{
													padding: "1em",
													textAlign: "Left",
												}}
												alignItems="end"
											>
												<span
													style={{
														fontSize: "2em",
														alignSelf: "flex-start",
													}}
												>
													Mangoes
												</span>
												<p>
													Kenyan mangos are some of the world & #39;s best,
													making Kenya a vital supplier of luscious and
													nutritious mangos for the world&# 39; s main mango
													markets.Did you realize that mangos are the world &
													#39;s most popular fruit? This mature golden,
													succulent fruit with a nice flavor and pleasant scent
													has been a staple of many people&# 39; s daily diets
													worldwide for many years.Shobara Foods Inc.offers only
													the best Kenyan mangoes appropriate for the worldwide
													export market.Kenya mangos have a sweet flavor and are
													commonly used as a dish.It is also tasty when the pulp
													of raw mangoes is cooked, or the juice from mature
													fruits is extracted.{" "}
												</p>
												<NavbarBtn
													btnPath="readMore"
													btnLabel="Read More"
												>
													Read More
												</NavbarBtn>
											</Stack>
										</div>
									</Stack>
								</Paper>
							</Stack>
							<h1> Herbs </h1>
							<Stack
								justifyContent={"center"}
								alignItems="center"
								direction={{
									xs: "column",
									sm: "row",
								}}
								spacing={{
									xs: 1,
									sm: 2,
									md: 4,
								}}
							>
								<Paper
									sx={{
										padding: "1em",
										width: "30%",
										backgroundColor: "#ddf2a6",
										height: "35em",
									}}
								>
									<Stack
										direction={"column"}
										alignItems={"center "}
										sx={{
											textAlign: "Left",
										}}
									>
										<div>
											<img
												src={Marjoram}
												width="300em"
												height="200em"
											></img>
										</div>
										<div>
											<Stack
												direction={"column"}
												sx={{
													padding: "1em",
													textAlign: "Left",
												}}
												alignItems="end"
											>
												<span
													style={{
														fontSize: "2em",
														alignSelf: "flex-start",
													}}
												>
													Marjoram
												</span>
												<p>
													Marjoram, sometimes known as sweet marjoram, is a
													fragrant plant of the mint family that has been grown
													for thousands of years across the Mediterranean, North
													Africa, and Western Asia.It has a milder flavor than
													oregano and is frequently used to garnish salads,
													soups, and meat dishes.It is most effective when
													dried, although it may also be used fresh.{" "}
												</p>
												<NavbarBtn
													btnPath="readMore"
													btnLabel="Read More"
												>
													Read More
												</NavbarBtn>
											</Stack>
										</div>
									</Stack>
								</Paper>
								<Paper
									sx={{
										padding: "1em",
										width: "30%",
										backgroundColor: "#ddf2a6",
										height: "35em",
									}}
								>
									<Stack
										direction={"column"}
										alignItems={"center "}
										sx={{
											textAlign: "Left",
										}}
									>
										<div>
											<img
												src={Lemongrass}
												width="300em"
												height="200em"
											></img>
										</div>
										<div>
											<Stack
												direction={"column"}
												sx={{
													padding: "1em",
													textAlign: "Left",
												}}
												alignItems="end"
											>
												<span
													style={{
														fontSize: "2em",
														alignSelf: "flex-start",
													}}
												>
													Lemongrass
												</span>
												<p>
													Lemongrass is a thick, tall grass with leaves on top
													and a solid section several inches long at the root
													end.The lowest piece is cut or crushed and
													cooked.Fresh lemon grass is favored as a spice because
													of its lively flavor, although it is also available in
													dried form.The dried spice is available in a variety
													of forms, including sliced in slices, cut and sifted,
													powdered, and as an oil produced from the plant.{" "}
												</p>
												<NavbarBtn
													btnPath="readMore"
													btnLabel="Read More"
												>
													Read More
												</NavbarBtn>
											</Stack>
										</div>
									</Stack>
								</Paper>
							</Stack>
							<br></br>
							<Stack
								justifyContent={"center"}
								alignItems="center"
								mt="1em"
								direction={{
									xs: "column",
									sm: "row",
								}}
								spacing={{
									xs: 1,
									sm: 2,
									md: 4,
								}}
							>
								<Paper
									sx={{
										padding: "1em",
										width: "30%",
										backgroundColor: "#ddf2a6",
										height: "35em",
									}}
								>
									<Stack
										direction={"column"}
										alignItems={"center "}
										sx={{
											textAlign: "Left",
										}}
									>
										<div>
											<img
												src={Tarragon}
												width="300em"
												height="200em"
											></img>
										</div>
										<div>
											<Stack
												direction={"column"}
												sx={{
													padding: "1em",
													textAlign: "Left",
												}}
												alignItems="end"
											>
												<span
													style={{
														fontSize: "2em",
														alignSelf: "flex-start",
													}}
												>
													Tarragon
												</span>
												<p>
													Is a culinary herb with glossy, thin leaves and a
													fragrant taste. The French variety is the most
													commonly used in cooking. Tarragon is used in many
													French cuisines, including Bearnaise Sauce, and its
													delicate flavor complements fish, poultry, and eggs.
													It is known as &quot;the king of herbs&quot; in France
													because of its capacity to elevate a meal, and it is
													one of four herbs in the French mixture fines herbes,
													which includes parsley, tarragon, chervil, and chives.
												</p>
												<NavbarBtn
													btnPath="readMore"
													btnLabel="Read More"
												>
													Read More
												</NavbarBtn>
											</Stack>
										</div>
									</Stack>
								</Paper>
								<Paper
									sx={{
										padding: "1em",
										width: "30%",
										backgroundColor: "#ddf2a6",
										height: "35em",
									}}
								>
									<Stack
										direction={"column"}
										alignItems={"center "}
										sx={{
											textAlign: "Left",
										}}
									>
										<div>
											<img
												src={Chives}
												width="300em"
												height="200em"
											></img>
										</div>
										<div>
											<Stack
												direction={"column"}
												sx={{
													padding: "1em",
													textAlign: "Left",
												}}
												alignItems="end"
											>
												<span
													style={{
														fontSize: "2em",
														alignSelf: "flex-start",
													}}
												>
													Chives
												</span>
												<p>
													Chives, a member of the onion family, are one of the
													world&#39;s most popular herbs. They have a milder,
													more delicate taste than their cousins. Their delicate
													onion taste is ideal for savory foods and situations
													where the bite of onions or garlic would be too
													strong. Shobara Foods chives are snipped at their
													height of flavor and quickly freeze-dried to preserve
													their bright taste, color, and texture. Because heat
													ruins the delicate flavor of chives, save them for the
													last five or ten minutes of cooking.
												</p>
												<NavbarBtn
													btnPath="readMore"
													btnLabel="Read More"
												>
													Read More
												</NavbarBtn>
											</Stack>
										</div>
									</Stack>
								</Paper>
							</Stack>
							<Stack
								justifyContent={"center"}
								alignItems="center"
								mt={"1em"}
								direction={{
									xs: "column",
									sm: "row",
								}}
								spacing={{
									xs: 1,
									sm: 2,
									md: 4,
								}}
							>
								<Paper
									sx={{
										padding: "1em",
										width: "30%",
										backgroundColor: "#ddf2a6",
										height: "35em",
									}}
								>
									<Stack
										direction={"column"}
										alignItems={"center "}
										sx={{
											textAlign: "Left",
										}}
									>
										<div>
											<img
												src={WinterThyme}
												width="300em"
												height="200em"
											></img>
										</div>
										<div>
											<Stack
												direction={"column"}
												sx={{
													padding: "1em",
													textAlign: "Left",
												}}
												alignItems="end"
											>
												<span
													style={{
														fontSize: "2em",
														alignSelf: "flex-start",
													}}
												>
													Winter Thyme
												</span>
												<p>
													Winter thyme is a hardy evergreen perennial with
													fragrant dark green foliage and clusters of tiny pink
													flowers. A versatile culinary herb that may be used to
													flavor meats, soups, and stews. &#39;Bouquet Garni and
													&#39;herbs de Provence&#39; are traditional
													ingredients. Thyme oil is a disinfectant and can
													assist with skin diseases such as eczema.
												</p>
												<NavbarBtn
													btnPath="readMore"
													btnLabel="Read More"
												>
													Read More
												</NavbarBtn>
											</Stack>
										</div>
									</Stack>
								</Paper>
								<Paper
									sx={{
										padding: "1em",
										width: "30%",
										backgroundColor: "#ddf2a6",
										height: "35em",
									}}
								>
									<Stack
										direction={"column"}
										alignItems={"center "}
										sx={{
											textAlign: "Left",
										}}
									>
										<div>
											<img
												src={Oregano}
												width="300em"
												height="200em"
											></img>
										</div>
										<div>
											<Stack
												direction={"column"}
												sx={{
													padding: "1em",
													textAlign: "Left",
												}}
												alignItems="end"
											>
												<span
													style={{
														fontSize: "2em",
														alignSelf: "flex-start",
													}}
												>
													Oregano
												</span>
												<p>
													Oregano is a well-known herb found in meals such as
													pizza and pasta sauce. It is one of the most
													extensively used herbs in the world and may be found
													in Mediterranean and Mexican cuisines, as well as in
													chili powder. Although dried oregano is most commonly
													used, fresh oregano is also used in some cuisines. It
													is available in both versions all year.
												</p>
												<NavbarBtn
													btnPath="readMore"
													btnLabel="Read More"
												>
													Read More
												</NavbarBtn>
											</Stack>
										</div>
									</Stack>
								</Paper>
							</Stack>
							<Stack
								justifyContent={"center"}
								alignItems="center"
								mt={"1em"}
								direction={{
									xs: "column",
									sm: "row",
								}}
								spacing={{
									xs: 1,
									sm: 2,
									md: 4,
								}}
							>
								<Paper
									sx={{
										padding: "1em",
										width: "30%",
										backgroundColor: "#ddf2a6",
										height: "35em",
									}}
								>
									<Stack
										direction={"column"}
										alignItems={"center "}
										sx={{
											textAlign: "Left",
										}}
									>
										<div>
											<img
												src={Rosemary}
												width="300em"
												height="200em"
											></img>
										</div>
										<div>
											<Stack
												direction={"column"}
												sx={{
													padding: "1em",
													textAlign: "Left",
												}}
												alignItems="end"
											>
												<span
													style={{
														fontSize: "2em",
														alignSelf: "flex-start",
													}}
												>
													Rosemary
												</span>
												<p>
													Rosemary has a long history of usage in both cooking
													and medicine. It grows in bushes with wood-like stems
													and short, pine-like needles, with white, pink,
													purple, or blue flowers. Although it resembles pine in
													both look and aroma, rosemary, like sage and mint, is
													a member of the plant family Lamiaceae. Rosemary is a
													perennial evergreen shrub that is frequently planted
													as an attractive shrub due to its capacity to endure
													severe environments.
												</p>
												<NavbarBtn
													btnPath="readMore"
													btnLabel="Read More"
												>
													Read More
												</NavbarBtn>
											</Stack>
										</div>
									</Stack>
								</Paper>
								<Paper
									sx={{
										padding: "1em",
										width: "30%",
										height: "35em",

										backgroundColor: "#ddf2a6",
									}}
								>
									<Stack
										direction={"column"}
										alignItems={"center "}
										sx={{
											textAlign: "Left",
										}}
									>
										<div>
											<img
												src={Basil}
												width="300em"
												height="200em"
											></img>
										</div>
										<div>
											<Stack
												direction={"column"}
												sx={{
													padding: "1em",
													textAlign: "Left",
												}}
												alignItems="end"
											>
												<span
													style={{
														fontSize: "2em",
														alignSelf: "flex-start",
													}}
												>
													Basil
												</span>
												<p>
													Basil is a mint-family plant that is crucial in
													Italian cookery, but it is also utilized in Thai,
													Indonesian, and Vietnamese cuisines. It is well-known
													as the principal component in traditional pesto and as
													a flavoring in tomato-based pasta sauces. Basil is an
													annual plant that is typically green. It has a
													fragrant, sweet aroma and a spicy flavor.
												</p>
												<NavbarBtn
													btnPath="readMore"
													btnLabel="Read More"
												>
													Read More
												</NavbarBtn>
											</Stack>
										</div>
									</Stack>
								</Paper>
							</Stack>{" "}
							<Stack
								justifyContent={"center"}
								alignItems="center"
								mt="1em"
								direction={{
									xs: "column",
									sm: "row",
								}}
								spacing={{
									xs: 1,
									sm: 2,
									md: 4,
								}}
							>
								<Paper
									sx={{
										padding: "1em",
										width: "30%",
										height: "35em",
										backgroundColor: "#ddf2a6",
									}}
								>
									<Stack
										direction={"column"}
										alignItems={"center "}
										sx={{
											textAlign: "Left",
										}}
									>
										<div>
											<img
												src={LemonThyme}
												width="300em"
											></img>
										</div>
										<div>
											<Stack
												direction={"column"}
												sx={{
													padding: "1em",
													textAlign: "Left",
												}}
												alignItems="end"
											>
												<span
													style={{
														fontSize: "2em",
														alignSelf: "flex-start",
													}}
												>
													Lemon Thyme
												</span>
												<p>
													This lemony, vibrant, and fragrant thyme kind is
													popular in salads, summer recipes, and even drinks. As
													tempting as it is to use it in any dish that asks for
													thyme, do so only when both thyme and citrus are
													required. Lemon thyme is delicious on a basic roast
													chicken, for example.
												</p>
												<NavbarBtn
													btnPath="readMore"
													btnLabel="Read More"
												>
													Read More
												</NavbarBtn>
											</Stack>
										</div>
									</Stack>
								</Paper>
								<Paper
									sx={{
										padding: "1em",
										width: "30%",
										height: "35em",

										backgroundColor: "#ddf2a6",
									}}
								>
									<Stack
										direction={"column"}
										alignItems={"center "}
										sx={{
											textAlign: "Left",
										}}
									>
										<div>
											<img
												src={Melissa}
												width="300em"
												height={"80%"}
											></img>
										</div>
										<div>
											<Stack
												direction={"column"}
												sx={{
													padding: "1em",
													textAlign: "Left",
												}}
												alignItems="end"
											>
												<span
													style={{
														fontSize: "2em",
														alignSelf: "flex-start",
													}}
												>
													Melissa
												</span>
												<p>
													Melissa means &quot;bee&quot; in Greek, and it
													received that name because this lovely plant with its
													little white blooms laden with nectar is a favorite of
													bees. It is used mostly as a digestive drink, but it
													is also incredibly calming, and a warm cup can ease
													tension and promote deep sleep. Did you know its
													leaves have a distinct lemon aroma and may be used as
													a natural insect repellent?
												</p>
												<NavbarBtn
													btnPath="readMore"
													btnLabel="Read More"
												>
													Read More
												</NavbarBtn>
											</Stack>
										</div>
									</Stack>
								</Paper>
							</Stack>{" "}
						</Grid>
					</Grid>
				</div>
			</Grid>
			<Footer />
		</div>
	)
}
