import { Grid,Stack,useMediaQuery } from '@mui/material'
import NavBar from '../organisms/NavBar'
import React from 'react'
import '../pages/about.css'
import Footer from '../organisms/Footer'
import aboutImg from '../img/aboutImg.svg'
import MarketPLaceCard from '../atoms/MarketPLaceCard'
import placeHolderImg from '../img/placeHolder.png'


export default function MarketPlace() {
  const smallScreen = useMediaQuery('(min-width:600px)');
    const porpList = [
        {title:'prop1'},
        {title:'prop2'},
        {title:'prop3'},
        {title:'prop4'},
        {title:'prop5'},
        {title:'prop6'},
        {title:'prop7'},
      ]
  return (
    <div>
      <Grid container direction={"column"}>
        <div className='aboutContainer'>
          <Grid item>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              spacing={12}
              sx={{ paddingTop: "2em" }}
            >
              <div>
                <h1
                  style={{
                    fontFamily: "Julius Sans One",
                    paddingLeft: smallScreen ? "3em" : "0.5em",
                  }}
                >
                  SHOBARA FOODS INC.
                </h1>
              </div>
              <div style={{ float: "right" }}>
                <NavBar></NavBar>
              </div>
            </Stack>
          </Grid>
          <Grid item>
            <h1
              style={{
                fontFamily: "quicksand",
                fontSize: "3em",
                textAlign: "center",
                fontWeight: "normal",
              }}
            >
              Our services
            </h1>
          </Grid>
          <Grid item>
            <Stack
              direction={{ xs: "column", md: "row" }}
              alignItems='start'
              justifyContent={"space-evenly"}
            >
              <div
                style={{
                  textAlign: "center",
                  width: "40%",
                  fontWeight: "bolder",
                }}
              >
                <h1
                  style={{
                    fontSize: "2.5em",
                    fontFamily: "quicksand",
                  }}
                >
                  Logistics
                </h1>
                <p
                  style={{
                    fontSize: "1.4em",
                    fontFamily: "Inconsolata",
                  }}
                >
                  We completely control the manufacturing
                  process, logistics, and market distribution. We
                  specialize in sea freight using reefer
                  containers and airfreight to any destination.
                  We provide good communication and coordination
                  for sea transportation, where the fruit is
                  brought to Mombasa port and containers are then
                  put at the suitable temperature and Quality
                  Assurance mechanisms.
                </p>
                <p
                  style={{
                    fontSize: "1.4em",
                    fontFamily: "Inconsolata",
                  }}
                >
                  Technical monitoring of the container onboard
                  the vessel continues until final discharge.
                  During the operational process of physical
                  product handling, a concurrent stream of
                  paperwork flow happens to assure product
                  legality and 100% traceability.
                </p>
              </div>
              <div
                style={{
                  textAlign: "center",
                  width: "40%",
                  fontWeight: "bolder",
                }}
              >
                <h1
                  style={{
                    fontSize: "2.5em",
                    fontFamily: "quicksand",
                  }}
                >
                  Marketing
                </h1>
                <p
                  style={{
                    fontSize: "1.4em",
                    fontFamily: "Inconsolata",
                  }}
                >
                  Our goods are exported to the following
                  countries: Europe, Asia (China, Hong Kong,
                  Malaysia, Singapore), the Middle East (all GCC
                  nations), and Africa (South Africa, Morocco,
                  and Egypt).
                </p>
                <p
                  style={{
                    fontSize: "1.4em",
                    fontFamily: "Inconsolata",
                  }}
                >
                  We have an experienced workforce that is
                  well-versed in the needs of various export
                  markets.
                </p>
              </div>
            </Stack>
          </Grid>
          <Grid item>
            <div
              style={{
                textAlign: "center",
                width: "60%",
                fontWeight: "bolder",
                margin: "auto",
              }}
            >
              <h1
                style={{
                  fontSize: "2.5em",
                  fontFamily: "quicksand",
                }}
              >
                Production
              </h1>
              <p
                style={{
                  fontSize: "1.4em",
                  fontFamily: "Inconsolata",
                }}
              >
                We acquire our export products from our farms as
                well as contracted growers.
              </p>
              <h3 style={{ fontFamily: "Julius Sans One" }}>
                Company Farms
              </h3>
              <p
                style={{
                  fontSize: "1.4em",
                  fontFamily: "Inconsolata",
                }}
              >
                Our fruits are grown under Good Agricultural
                Practices guidelines. We never use synthetic
                fertilizers on crops since we always use organic
                farming and natural pest and disease management.
                Our fruits are gathered at their peak and in the
                optimum shape for export markets. We dedicate
                fruit maturity testing, fields are eventually
                cleared for harvest, and our harvest crew enters
                to carefully select the fruit for quick transport
                into the packaging factory.
              </p>
              <h3 style={{ fontFamily: "Julius Sans One" }}>
                Our outgrowing farmers
              </h3>
              <p
                style={{
                  fontSize: "1.4em",
                  fontFamily: "Inconsolata",
                }}
              >
                We have chosen and hired Kenyan farmers to help
                through:
              </p>
              <p
                style={{
                  fontSize: "1.4em",
                  fontFamily: "Inconsolata",
                  textAlign: "left",
                }}
              >
                <ul>
                  <li>
                    Providing a stable and long-term market.
                  </li>
                  <li>
                    Negotiate and agree on competitive rates with
                    one another.
                  </li>
                  <li>
                    Providing technical help to improve quality
                    and quantity through capacity building and
                    regular visits by our technical experts.
                  </li>
                  <li>
                    Facilitating access to a wide range of
                    government and development partner facilities
                    and services.
                  </li>
                  <li>
                    Establishing a communication and consultation
                    platform.
                  </li>
                  <li>
                    Immediate information on emerging concerns.
                  </li>
                </ul>
              </p>
            </div>
          </Grid>
        </div>
      </Grid>
      <Footer />
    </div>
  )
}
