import React from 'react';
import logo from './logo.svg';
import { BrowserRouter as Router,Routes,Route } from 'react-router-dom';
import './App.css';
import NavbarBtn from './components/atoms/NavbarBtn';
import NavBar from './components/organisms/NavBar';
import FooterNavBarBtn from './components/atoms/FooterNavBarBtn';
import Footer from './components/organisms/Footer';
import LandingPage from './components/pages/LandingPage';
import About from './components/pages/About';
import Contact from './components/pages/Contact';
import MarketPlace from './components/pages/MarketPlace';
import Ourservices from './components/pages/Ourservices';

function App() {
  return (
    <div> 
      <Router>
        <Routes>
          <Route exact path='/' element={<LandingPage/>}></Route>
          <Route exact path='/about' element={<About/>}></Route>
          <Route exact path='/contact' element={<Contact/>}></Route>
          <Route exact path='/mkt' element={<MarketPlace/>}></Route>
          <Route exact path='/service' element={<Ourservices/>}></Route>
        </Routes> 

      </Router>
    </div>
    
  );
}

export default App;
