import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';

export default function Drawer() {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <Link to="/" style={{textDecoration:"none"}}>
          <ListItem  >
            <ListItemButton>
              <ListItemIcon>
                    Home                
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to="/about" style={{textDecoration:"none"}}>
          <ListItem  >
            <ListItemButton>
              <ListItemIcon>
                    About                
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to="/contact" style={{textDecoration:"none"}}>
          <ListItem  >
            <ListItemButton>
              <ListItemIcon>
                    Contact                
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to="/mkt" style={{textDecoration:"none"}}>
          <ListItem  >
            <ListItemButton>
              <ListItemIcon>
                  Marketplace                
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </Link>
          

      </List>
     
    </Box>
  );

  return (
    <div style={{margin:"auto"}}>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor} >
          <Button onClick={toggleDrawer(anchor, true)} sx={{color:"black"}}>
                   <MenuIcon fontSize='large' sx={{margin:"auto"}}/>
          </Button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
